import Attachment from '@/components/fileUploading/attachment';
import AttachmentInput from '@/components/fileUploading/attachmentInput';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import { axiosClient } from '@/data';
import { auth } from '@/firebase/client';
import { Room, usePostMessage } from '@/firebase/firestore';
import { useCompanyId } from '@/providers/auth/useSessionStorageCompanyId';
import useUserInfo from '@/providers/auth/useUserInfo';
import { RoomType } from '@/types/chatRoom';
import { chatSettingsAtom, companyMapAtom } from '@/utils/atoms';
import {
	AddCircleOutlineRounded as AddCircleOutlineRoundedIcon,
	SendTwoTone as SendTwoToneIcon,
} from '@mui/icons-material';
import { Box, Grid, IconButton, InputAdornment, Paper, TextField, Tooltip, Typography } from '@mui/material';
import { useAtom } from 'jotai/index';
import { isEmpty } from 'lodash-es';
import { forwardRef, MutableRefObject, useEffect, useRef, useState } from 'react';

const BottomBar = forwardRef( ( {
	room,
	clientView = false,
}: {
	room: Room,
	clientView: boolean
}, ref: MutableRefObject<HTMLDivElement> ) => {
	const [ chatSettings ] = useAtom( chatSettingsAtom );
	const { postMessage } = usePostMessage( room.id );
	const { user, staff } = useUserInfo();
	const [ message, setMessage ] = useState( '' );
	const [ newFiles, setNewFiles ] = useState<string[]>( [] );
	const { sessionCompanyId } = useCompanyId();
	const [ companyAtom ] = useAtom( companyMapAtom );
	const sendBtnRef = useRef<HTMLButtonElement>( null );
	const [ sending, setSending ] = useState( false );
	
	useEffect( () => {
		setNewFiles( [] );
		setMessage( '' );
	}, [ chatSettings.chatRoomID ] );
	
	const sendMessage = async ( e ) => {
		e.preventDefault();
		if ( isEmpty( message || newFiles ) ) return;
		
		const myUid = auth.currentUser!.uid;
		const messageRef = await postMessage( {
			uid           : myUid,
			from          : staff?.email || user!.email,
			content       : message.trimEnd(),
			attachmentUrls: newFiles,
		} );
		
		setMessage( '' );
		setNewFiles( [] );
		
		if ( room.type === RoomType.AI ) {
			// await axiosClient.post( '/api/user/chat/AI/message', { roomId: room.id } );
			return;
		}
		
		const attendees = room.attendees;
		const attendeesWithoutMe = attendees.filter( ( uid ) => uid !== myUid );
		const senderName = user?.firstName + ' ' + user?.lastName;
		const companyEmail = companyAtom[ room.company ]?.email;
		const sendToEmails = clientView ? [ ...room.staffs, companyEmail ] : [ room.client ];
		const data = {
			companyId    : sessionCompanyId,
			uids         : attendeesWithoutMe,
			roomId       : room.id,
			messageObject: {
				from   : senderName.trim(),
				content: message.trimEnd(),
				id     : messageRef.id,
			},
			clientView   : clientView,
			sendToEmails,
		};
		axiosClient.post( `/api/user/chat/sendChatNotification`, data ).then();
	};
	
	async function handleSendMessage( e ) {
		setSending( true );
		await sendMessage( e ).finally( () => setSending( false ) );
	}
	
	return (
		<Box
			ref={ref}
			sx={{
				width       : '100%',
				borderRadius: 1,
				px          : 2,
				pb          : 1,
				bgcolor     : 'background.paper',
			}}>
			<Paper sx={{ p: 1, bgcolor: 'background.default', position: 'relative', zIndex: 2 }}>
				{Boolean( newFiles.length ) && (
					<Box sx={{ mb: .5 }}>
						{newFiles.map( ( file, index ) => (
							<Attachment
								key={index}
								src={file}
								onDelete={() => setNewFiles( [] )}
							/>
						) )}
					</Box>
				)}
				<Grid container spacing={1} sx={{ maxHeight: 500, alignItems: 'center' }}>
					<Grid item xs={12}>
						<TextField
							multiline
							hiddenLabel
							fullWidth
							autoFocus
							maxRows={20}
							variant='standard'
							size='medium'
							placeholder='Write your message here...'
							InputProps={{
								endAdornment    : message.length > 1950 && (
									<InputAdornment
										position='end'
										sx={{
											alignSelf   : 'start',
											p           : 1.5,
											borderRadius: 1,
											bgcolor     : 'error.main',
										}}>
										<Typography>{message.length}/2000</Typography>
									</InputAdornment>
								),
								disableUnderline: true,
							}}
							inputProps={{ maxLength: 2000 }}
							sx={{ borderRadius: 1, p: .5, py: 0 }}
							value={message}
							onChange={( e ) => setMessage( e.target.value )}
							onKeyDown={async ( e ) => {
								if ( sending ) return;
								
								if ( e.key === 'Enter' && !e.shiftKey ) {
									e.preventDefault(); // Prevent default behavior of Enter key
									
									if ( message.trim() && newFiles ) {
										sendBtnRef.current?.click(); // Trigger send button click
									}
								} else if ( e.shiftKey && e.key === 'Enter' ) {
									// Allow Shift+Enter to add a new line
								}
							}}
						/>
					</Grid>
					<Grid item container xs={12} justifyContent='space-between' alignItems='center'>
						{room.type === RoomType.AI ? <div/> : (
							<AttachmentInput
								hasButton
								files={newFiles}
								setFiles={setNewFiles}
								renderButton={( onClick ) => (
									<Tooltip title='You can Drag & Drop files or click this icon to upload'>
										<IconButton onClick={onClick}>
											<AddCircleOutlineRoundedIcon sx={{ color: 'text.secondary' }}/>
										</IconButton>
									</Tooltip>
								)}
							/>
						)}
						<AsyncLoadingButton
							ref={sendBtnRef}
							sx={{ px: 2 }}
							disabled={!newFiles.length && !message}
							startIcon={<SendTwoToneIcon/>}
							variant='contained'
							color='primary'
							onClick={handleSendMessage}>
							Send
						</AsyncLoadingButton>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
} );

export default BottomBar;
